import React, { useMemo } from 'react'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import moment from 'moment'
import orderBy from 'lodash/orderBy'
import replace from 'lodash/replace'
import sortBy from 'lodash/sortBy'
import sumBy from 'lodash/sumBy'
import { Badge } from 'reactstrap'
import { useSelector } from 'react-redux'
import { useTable } from 'react-table'

import MedicalImageThumbnail from '../common/MedicalImageThumbnail'
import { AiPredictionDenormalized, conditionsForSpecies, getPossibleConditions, receivingVetNotesIntro } from '../../lib/aiHelpers'
import { ConditionConfig } from '../ai-assessment/Indications'
import { Table, Header, HeaderCell, Cell, TextCell, Row, isColumn } from '../../components/common/Table'
import { consultationsSelector, ConsultationsState } from '../../hasura/slices/consultations'
import { emojiFor, keyForAwsS3Url, Option, pluralize, Species, speciesFor, vetNameWithoutQualifications } from '../../lib/helpers'
import { usersSelector, UsersState } from '../../hasura/slices/users'

import {
  ConsultationsForAiComparison_consultations,
  ConsultationsForAiComparison_consultations_case_medical_images,
  ConsultationsForAiComparison_consultations_case_medical_images_medical_image_permutations,
} from '../../hasura/graphQlQueries/types/ConsultationsForAiComparison'

// @ts-ignore
import aiConfig from '../../../aiConfig.json'

// @ts-ignore
import { get_condition_config, getLikelihood } from '../../lib/ml_rules_engine.js'

const defaultColumn = {
  Cell: TextCell,
}

interface Props {
  data: ConsultationsForAiComparison_consultations[]
  species: Species
  selectedCondition: Option | null
}

export default function FeedTable(props: Props) {
  const { vetsExpanded }: UsersState = useSelector(usersSelector)
  const { presignedCaseImageUrls, conditions }: ConsultationsState = useSelector(consultationsSelector)

  const { data } = props

  const columns = useMemo(
    () => [
      {
        Header: 'Case',
        accessor: (c: ConsultationsForAiComparison_consultations) => c,
        Cell: (c: { value: ConsultationsForAiComparison_consultations }) => {
          const vet = vetsExpanded.find((v) => v.id === c.value.receiving_vet?.id)

          return (
            <div>
              <p className="text-s mb-0 bold">{pluralize('image', c.value.case.medical_images.length)}</p>
              <p className="text-s mb-0">
                {emojiFor(speciesFor(c.value.case.patient.species))} {c.value.case.patient.display_name}
              </p>
              <p className="text-s mb-0">{vetNameWithoutQualifications(vet?.display_name)}</p>
              <p className="text-s mb-0">{moment(c.value.completed_at).format('LL')}</p>
            </div>
          )
        },
      },
      {
        Header: 'Specialist',
        accessor: (c: ConsultationsForAiComparison_consultations) => c,
        Cell: (c: { value: ConsultationsForAiComparison_consultations }) => {
          const hasAiPredictions = getPossibleConditions(c.value.case, conditions).length > 0
          return (
            <div className="position-relative">
              <div className="d-flex align-items-start flex-column">
                {!c.value.predictions.length ? (
                  <Badge color={hasAiPredictions ? 'danger' : 'success'}>Healthy</Badge>
                ) : (
                  orderBy(c.value.predictions, ['condition.display_name'], ['asc']).map((p, idx) => {
                    const caught = getPossibleConditions(c.value.case, conditions)
                      .map((c2) => c2.id)
                      .includes(p.condition?.id || 0)

                    const exists = conditionsForSpecies(props.species, conditions).includes(p.condition?.display_name || '')

                    return (
                      <Badge
                        className="mb-1 max-width-150px text-left multiline"
                        color={caught ? 'success' : exists ? 'warning' : 'info'}
                        style={{ textTransform: 'capitalize' }}
                        key={idx}
                      >
                        {p.condition?.display_name || `(Custom) ${p.display_name}`}
                      </Badge>
                    )
                  })
                )}
              </div>

              <p className="text-s m-0 max-height-600px overflow-scroll prewrap">
                {props.selectedCondition
                  ? replace(
                      c.value.receiving_vet_notes || '',
                      new RegExp(props.selectedCondition.label, 'i'),
                      props.selectedCondition.label.toUpperCase()
                    )
                  : c.value.receiving_vet_notes}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'AI',
        accessor: (c: ConsultationsForAiComparison_consultations) => c,
        Cell: (c: { value: ConsultationsForAiComparison_consultations }) => {
          const aiConditions = getPossibleConditions(c.value.case, conditions)

          let status
          if (props.selectedCondition) {
            const aiFound = aiConditions.some((a) => a.id === props.selectedCondition!.value)
            const specialistFound = c.value.predictions.some((p) => p.condition?.id === props.selectedCondition!.value)
            status = aiFound && specialistFound ? 'SUCCESS' : aiFound ? 'FALSE POSITIVE' : 'FALSE NEGATIVE'
          }

          return (
            <div>
              <div className="position-relative h-100">
                {aiConditions.map((a, idx) => {
                  const specialistFound = c.value.predictions.some((p) => p.condition?.id === a.id)
                  return (
                    <Badge
                      className="mb-1 d-block width-fit-content max-width-150px multiline text-left"
                      color={specialistFound ? 'success' : 'danger'}
                      style={{ textTransform: 'capitalize' }}
                      key={idx}
                    >
                      {a.display_name}
                    </Badge>
                  )
                })}

                <p className={`position-absolute b-0 r-0 text-m mt-2 bold ${status === 'SUCCESS' ? 'text-success' : 'text-danger'}`}>
                  {status}
                </p>
              </div>

              <p className="text-s m-0 max-height-300px overflow-scroll prewrap">{receivingVetNotesIntro(c.value)}</p>
            </div>
          )
        },
      },

      {
        Header: 'Images',
        accessor: (c: ConsultationsForAiComparison_consultations) => c,
        Cell: (c: { value: ConsultationsForAiComparison_consultations }) => {
          type ImageWithPredictions =
            | ConsultationsForAiComparison_consultations_case_medical_images_medical_image_permutations
            | ConsultationsForAiComparison_consultations_case_medical_images

          const instanceOfMedicalImage = (
            image: ImageWithPredictions
          ): image is ConsultationsForAiComparison_consultations_case_medical_images => 'view' in image

          const images = c.value.case.medical_images

          return (
            <div>
              <div className="d-flex flex-column gap-20px max-height-600px overflow-scroll">
                {sortBy(images, (m) => -sumBy((m.ai_predictions_denormalized as AiPredictionDenormalized[]) || [], 'probability')).map(
                  (i, idx) => {
                    const src = presignedCaseImageUrls.find((p) => i.aws_s3_url && p.includes(i.aws_s3_url))
                    if (!src) {
                      console.log(`WARNING no presigned url for ${i.aws_s3_url}`)
                      return null
                    }

                    const heatmaps = presignedCaseImageUrls.filter(
                      (p) => p !== src && i.aws_s3_url && p.includes(keyForAwsS3Url(i.aws_s3_url)!.replace('.jpg', '_'))
                    )

                    // @ts-ignore
                    const withPermutations: ImageWithPredictions[] = [i].concat(...i.medical_image_permutations)

                    const predictions = flatten(
                      withPermutations.map((w) => {
                        const ai_predictions_denormalized = w.ai_predictions_denormalized as AiPredictionDenormalized[]
                        const isCropped = !instanceOfMedicalImage(w)

                        return compact(
                          sortBy(ai_predictions_denormalized || [], 'probability')
                            .reverse()
                            .map((p) => {
                              const condition = conditions.find((c) => c.ml_name === p.ml_name)
                              if (!condition) return null

                              const specialistFound = c.value.predictions.some((p) => p.condition?.id === condition.id)
                              const aiFound = getLikelihood(p, i.view, props.species, isCropped, aiConfig, false) !== undefined
                              const conditionConfig: ConditionConfig = get_condition_config(aiConfig, p, props.species, isCropped)
                              if (!specialistFound && !aiFound) return null

                              const correct = aiFound && specialistFound
                              const falseNegative = aiFound && !specialistFound
                              return { condition, p, correct, falseNegative, conditionConfig, key: i.aws_s3_url }
                            })
                        )
                      })
                    )

                    return (
                      <div className="d-flex gap-10px" key={idx}>
                        <div>
                          <MedicalImageThumbnail src={src} view={i.view?.display_name} px={200} />
                          <p className="text-xs mt-3 mb-0">{keyForAwsS3Url(src.split('?')[0])}</p>
                        </div>

                        {heatmaps.map((h, idx2) => {
                          const heatmapKey = h.split('?')[0].split('/').pop()
                          const keySplit = heatmapKey?.split('_')
                          const ml_name = heatmapKey?.split('_').slice(2, keySplit?.length).join(' ').split('.')[0]
                          const key = heatmapKey?.split('_').slice(0, 2).join('_') || ''
                          const prediction = predictions.find((p) => p.key?.includes(key) && ml_name === p.condition?.ml_name)
                          if (!prediction) return null

                          const thresholds = compact([
                            prediction.conditionConfig?.potential_p_th,
                            prediction.conditionConfig?.possible_p_th,
                            prediction.conditionConfig?.p_th,
                          ])
                            .map((n) => (n * 100).toFixed(0))
                            .join(' / ')

                          return (
                            <div key={idx2}>
                              <MedicalImageThumbnail src={h} view={''} px={200} />

                              <p className="m-0 text-xs single-line" key={idx}>
                                <span
                                  className={`mr-1 
                                    ${prediction.correct ? 'text--success bold' : prediction.falseNegative ? 'text--danger bold' : ''}
                                  `}
                                >
                                  {prediction.condition.display_name}
                                </span>
                                {(prediction.p.probability * 100).toFixed(0)} (min. {thresholds})
                              </p>
                            </div>
                          )
                        })}
                      </div>
                    )
                  }
                )}
              </div>
            </div>
          )
        },
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    // @ts-ignore
    columns,
    data,
    defaultColumn,
  })

  const colSpan = (data: any) => (isColumn(data, 'Images') ? 5 : isColumn(data, 'Specialist') ? 3 : 1)

  return (
    <Table cellSpacing={0} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup: any, idx: number) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => {
              return (
                <HeaderCell key={idx} {...column.getHeaderProps()} colSpan={colSpan(column)}>
                  <Header>{column.render('Header')}</Header>
                </HeaderCell>
              )
            })}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, idx: number) => {
          prepareRow(row)

          return (
            <Row key={idx} {...row.getRowProps()}>
              {row.cells.map((cell: any, idx2: number) => {
                return (
                  <Cell style={{ verticalAlign: 'top' }} key={idx2} {...cell.getCellProps()} colSpan={colSpan(cell)}>
                    {cell.render('Cell')}
                  </Cell>
                )
              })}
            </Row>
          )
        })}
      </tbody>
    </Table>
  )
}
