import DatePicker from 'react-datepicker'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import flatten from 'lodash/flatten'
import moment from 'moment'
import { Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import 'react-datepicker/dist/react-datepicker.css'

import FeedTable from '../components/feed/table'
import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import RadioButton from '../components/common/RadioButton'
import SpeciesSelect from '../components/common/SpeciesSelect'
import formatGroupLabel from '../components/consultations/GroupLabel'
import { PredictionSource } from '../hasura/graphQlQueries/Consultation'
import { QueryName } from '../hasura/queryNames'
import { conditionOptions, Option, searchQueryParams, Species } from '../lib/helpers'
import { consultationsSelector, ConsultationsState, fetchConsultationsForAiComparisonAction } from '../hasura/slices/consultations'
import { getPossibleConditions } from '../lib/aiHelpers'
import { usersSelector, UsersState } from '../hasura/slices/users'

// @ts-ignore
import rightArrow from '../lib/images/right-arrow.svg'

export default function Feed() {
  const dispatch = useDispatch()

  const [selectedCondition, setSelectedCondition] = useState<Option | null>(null)
  const [predictionSource, setPredictionSource] = useState<PredictionSource>(PredictionSource.Specialist)
  const [species, setSpecies] = useState(Species.Dog)
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months').toDate())

  const { accessToken, vetsExpanded }: UsersState = useSelector(usersSelector)
  const { consultationsForAiComparison, conditions, isQuerying }: ConsultationsState = useSelector(consultationsSelector)

  const options = conditionOptions(conditions, [])
  const queryParamConditionId = parseInt(searchQueryParams('c') || '', 10)

  useEffect(() => {
    const condition = flatten(options.map((o) => o.options)).find((o) => o.value === queryParamConditionId) || null
    if (!condition || !conditions.length) return

    setSelectedCondition(condition)
  }, [conditions])

  useEffect(() => {
    if ((queryParamConditionId && !selectedCondition) || !accessToken) return

    fetchConsultations()
  }, [accessToken, selectedCondition, predictionSource, species, startDate])

  const fetchConsultations = () => {
    dispatch(
      // @ts-ignore
      fetchConsultationsForAiComparisonAction(accessToken, predictionSource, conditions, species, startDate, selectedCondition?.value)
    )
  }

  const agreementCount = selectedCondition
    ? predictionSource === PredictionSource.AI
      ? consultationsForAiComparison?.filter((c) => c.predictions.some((c) => c.condition?.id === selectedCondition.value)).length
      : consultationsForAiComparison?.filter((c) =>
          getPossibleConditions(c.case, conditions)
            .map((c) => c.id)
            .includes(selectedCondition.value)
        ).length
    : 0

  return (
    <Layout>
      <MainBox defaultPadding>
        <div className="d-flex mb-4">
          <h4 className="bold mr-5">AI Feed</h4>

          <SpeciesSelect setSpecies={setSpecies} species={species} />

          <div className="ml-4 min-width-300px">
            <Select
              isClearable
              isDisabled={isQuerying[QueryName.FetchConsultationsForAiComparison]}
              onChange={(option) => setSelectedCondition(option)}
              options={options}
              placeholder="Select condition..."
              value={selectedCondition}
              // @ts-ignore
              formatGroupLabel={formatGroupLabel}
            />

            {selectedCondition && (
              <div className="mt-2">
                <p className="bold text-m gray8 mb-0">Created after</p>

                <DatePicker
                  dateFormat="MM/dd/yy"
                  filterDate={(date: any) => moment() > date}
                  className="react-select-styles"
                  selected={startDate}
                  onChange={(date: any) => setStartDate(date)}
                />
              </div>
            )}
          </div>
        </div>

        {isQuerying[QueryName.FetchConsultationsForAiComparison] || !vetsExpanded.length ? (
          <Spinner color="primary" />
        ) : (
          <div>
            {selectedCondition && (
              <div className="mb-3">
                <p className="text-l m-0 text--gray7">
                  Cases after <span className="text--black semibold">{startDate.toLocaleDateString()}</span> where{' '}
                  <span className="text--black semibold">{predictionSource}</span> predicted{' '}
                  <span className="text--black semibold">{selectedCondition?.label}</span>:
                  {agreementCount !== undefined && (consultationsForAiComparison?.length || 0 > 0) && (
                    <span className="ml-2 text--black semibold">
                      {agreementCount}/{consultationsForAiComparison!.length} (
                      {Math.round((agreementCount * 100) / consultationsForAiComparison!.length)}%{' '}
                      {predictionSource === PredictionSource.AI ? 'precision' : 'recall'})
                    </span>
                  )}
                </p>

                <div className="d-flex gap-10px mt-1">
                  <RadioButton
                    onClick={() => setPredictionSource(PredictionSource.AI)}
                    checked={predictionSource === PredictionSource.AI}
                    label={PredictionSource.AI}
                  />

                  <RadioButton
                    onClick={() => setPredictionSource(PredictionSource.Specialist)}
                    checked={predictionSource === PredictionSource.Specialist}
                    label={PredictionSource.Specialist}
                  />
                </div>
              </div>
            )}

            <FeedTable selectedCondition={selectedCondition} species={species} data={consultationsForAiComparison || []} />
          </div>
        )}
      </MainBox>
    </Layout>
  )
}
